import couponService from './coupon.js';
import storageService from './storage.js';
import Product from '../model/product';

window.onload = function () {
  const select_product_btn = document.querySelector('#select_product');
  if (!select_product_btn) {
    return;
  }
  select_product_btn.addEventListener('click', selectProduct);

  if (
    storageService().tests.length > 0 &&
    document.querySelector(`[data-qa-id="Product - ${storageService().tests[0].name}"]`)
  ) {
    document
      .querySelector(`[data-qa-id="Product - ${storageService().tests[0].name}"]`)
      .classList.add('jcf-label-active');
  } else {
    document.querySelectorAll('.check-item.product-list-item')[0]?.classList.add('jcf-label-active');
  }

  /**
   * Store selected product in localStorage
   */
  function selectProduct() {
    const product = getSelectedProductDataset();

    if (!product || product.price <= 0) {
      return;
    }

    storageService().tests = [{ name: product.name, price: parseInt(product.price) }];
    storageService().extraCharges =
      product.extraCharge > 0
        ? [{ name: product.name, amount: parseInt(product.extraCharge), type: 'Extra Charge' }]
        : [];
    storageService().treatmentType = product.treatmentType || null;

    couponService().applyDefaultCoupon();

    window.location.href = '/select-pharmacy';
  }

  /**
   * Retrieve the currently selected product.
   *
   * @returns {Product} the object of the selected product element.
   */
  function getSelectedProductDataset() {
    let product = document.querySelectorAll('.product-list-item.jcf-label-active')[0];
    let values = product ? product.dataset : select_product_btn.dataset;

    return new Product(values.productName, values.productPrice, values.productExtraCharge, values.productTreatmentType);
  }
};
