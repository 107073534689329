/**
 * Service class for accessing localStorage.
 */
export default function storageService() {
  /**
   * Keys used to reference data in localStorage
   */
  const TESTS_KEY = 'ngStorage-tests';
  const EXTRA_CHARGES_KEY = 'ngStorage-extraCharges';
  const COUPON_KEY = 'ngStorage-coupon';
  const TREATMENT_TYPE_KEY = 'ngStorage-treatmentType';

  /**
   * Retrieves a value stored as JSON in localStorage
   *
   * @param {string} key the key to retrieve from storage
   *
   * @return {any}  The value stored in localStorage
   */
  function getLocalStorage(key) {
    return JSON.parse(window.localStorage?.getItem(key) || null);
  }

  /**
   * Saves a value in localStorage as a JSON string
   *
   * @param {string} key The key to store the value in
   * @param {any} value  The value to be stored
   *
   * @return {void}
   */
  function setLocalStorage(key, value) {
    window.localStorage?.setItem(key, JSON.stringify(value || null));
  }

  /**
   * Deletes a value from the storage.
   *
   * @param key {string} The value's key.
   *
   * @return void
   */
  function removeLocalStorage(key) {
    window.localStorage?.removeItem(key);
  }

  // Initialize tests in localStorage
  if (!getLocalStorage(TESTS_KEY)) {
    setLocalStorage(TESTS_KEY, []);
  }

  return {
    /**
     * Local Storage get, set and remove
     */
    localStorage: {
      get: getLocalStorage,
      set: setLocalStorage,
      remove: removeLocalStorage,
    },

    /**
     * Retrieve selected tests data from local storage
     */
    get tests() {
      return this.localStorage.get(TESTS_KEY) ?? [];
    },

    /**
     * Store selected tests data in local storage
     *
     * @param {object[]} tests the tests data to store
     */
    set tests(tests) {
      this.localStorage.set(TESTS_KEY, tests);
    },

    /**
     * Store selected tests data in local storage
     *
     * @param {object[]} extraCharges the tests data to store
     */
    set extraCharges(extraCharges) {
      this.localStorage.set(EXTRA_CHARGES_KEY, extraCharges);
    },

    /**
     * Store coupon data in local storage
     *
     * @param {object} coupon the coupon data to store
     */
    set coupon(coupon) {
      this.localStorage.set(COUPON_KEY, coupon);
    },

    /**
     * Store treatment type in local storage
     *
     * @param {string} treatmentType the treatment type to store
     */
    set treatmentType(treatmentType) {
      this.localStorage.set(TREATMENT_TYPE_KEY, treatmentType);
    },
  };
}
