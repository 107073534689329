import storageService from './storage.js';

/**
 * Service class for managing coupons.
 */
export default function couponService() {
  const couponElement = document.querySelector('#product_coupon');

  /**
   * Stores the default coupon in localStorage.
   */
  function applyDefaultCoupon() {
    if (!couponElement) {
      return;
    }

    storageService().coupon = getCouponData();
  }

  /**
   * Retrieves coupon data from the coupon element.
   */
  function getCouponData() {
    return {
      coupon_code: couponElement?.dataset.couponCode || null,
      coupon_amount: Number(couponElement?.dataset.couponAmount) || null,
      coupon_name: couponElement?.dataset.couponName || null,
      coupon_type: couponElement?.dataset.couponType || null,
    };
  }

  return {
    applyDefaultCoupon,
  };
}
